import React, { useState, useRef } from "react";
import "../core-ui/DemoPage.css";
import ReCAPTCHA from "react-google-recaptcha";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { Link } from "react-router-dom";
import Axios from '../axios';

const DemoPage = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [message, setMessage] = useState(false);

  const [inputValue, setInputValue] = useState({
    name: "",
    client_users_phone: "",
    email: "",
    company: "",
    comments: "",
    contact_us_secret_key: '6LcaPT0jAAAAAHBo-YA2tEwEdyFhSku3tFT9oX9A',
    contact_us_response: ""
  });

  // State to track reCAPTCHA verification status
  const [recaptchaVerified, setRecaptchaVerified] = useState(false);

  // Refs for each input
  const nameRef = useRef(null);
  const phoneRef = useRef(null);
  const emailRef = useRef(null);
  const companyRef = useRef(null);
  const commentsRef = useRef(null);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInputValue((prevInputValues) => ({
      ...prevInputValues,
      [name]: value,
    }));
  };

  const handleKeyDown = (event, nextRef) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent form submission on Enter
      nextRef.current.focus(); // Move focus to the next input
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await Axios.post(
        '/auth/early_access',
        inputValue,
        { withCredentials: false },
        { headers: { 'Accept': 'application/json' } },
        { cors: { "Access-Control-Allow-Origin": "*", "Access-Control-Allow-Headers": "Content-Type" } }
      );

      setMessage({ message: 'Form Submitted Successfully' });
      setShowPopup(true);

    } catch (error) {
      console.error('Error calling API:', error);
    }
  };

  const onChange = (recaptchaResponse) => {
    setInputValue(prevState => ({
      ...prevState,
      contact_us_response: recaptchaResponse
    }));
    setRecaptchaVerified(true); // Set reCAPTCHA as verified
  };

  const isSubmitDisabled = !inputValue.name || !inputValue.client_users_phone || !inputValue.email || !inputValue.company || !recaptchaVerified;

  return (
    <>
      <div className="header-nav">
        <div className="header-container">
          <div className="header-section">
            <div className="logo">
              <img
                className="media-logo"
                src="images/img_epriselogo.png"
                alt="eprise-logo"
              />
            </div>
            <ul>
              <li><Link to="/">Home</Link></li>
            </ul>
          </div>
        </div>
      </div>

      <div className="demo-page">
        <div className="demo-container">
          <div className="demo-section">
            <div className="demo-section-content">
              <img
                className="DemoPage-logo"
                src="images/img_epriselogo2.png"
                alt="Eprise Logo"
              />
              <h2> Schedule your Demo</h2>
              <p>
                Experience seamless financial management with our intuitive
                accounting website. Streamline your bookkeeping, track expenses,
                and generate insightful reports effortlessly. Request a demo
                today and take control of your finances with ease.
              </p>
              <div className="demo-contacts">
                <div className="demo-contancts-row">
                  <img className="loc-icon" src="images/e1.png" alt="linledln" />
                  <p>support@qliclabs.com</p>
                </div>
                <div className="demo-contancts-row">
                  <img className="loc-icon" src="images/c1.png" alt="linledln" />
                  <p>+91 8904052211</p>
                </div>
                <div className="footer-logo">
                  <a href="https://www.youtube.com/@qliclabs" target="_blank" rel="noopener noreferrer">
                    <img className="Demo-Page-img" src="images/Youtube.png" alt="youtube" />
                  </a>
                  <a href="https://www.instagram.com/qliclabs/" target="_blank" rel="noopener noreferrer">
                    <img className="Demo-Page-img" src="images/Instagram1.png" alt="instagram" />
                  </a>
                  <a href="https://www.linkedin.com/company/31312745/admin/" target="_blank" rel="noopener noreferrer">
                    <img className="Demo-Page-img" src="images/in1.png" alt="linledln" />
                  </a>
                  <a href="https://twitter.com/qliclabs" target="_blank" rel="noopener noreferrer">
                    <img className="Demo-Page-img" src="images/Twitter1.png" alt="twitter" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="demo-section">
            <div className="demo-form">
              <form onSubmit={handleSubmit}>
                <input
                  ref={nameRef}
                  type="text" 
                  value={inputValue.name} 
                  onChange={handleInputChange}
                  onKeyDown={(e) => handleKeyDown(e, phoneRef)}
                  className="form-control" 
                  id="name" 
                  name="name" 
                  placeholder="Name" 
                  required
                />
                <input
                  ref={phoneRef}
                  type="text" 
                  value={inputValue.client_users_phone} 
                  onChange={handleInputChange}
                  onKeyDown={(e) => handleKeyDown(e, emailRef)}
                  className="form-control" 
                  id="client_users_phone" 
                  name="client_users_phone" 
                  placeholder="Phone" 
                  required
                  pattern="[0-9]{10}"
                  maxLength="10"
                  onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')}
                />
                <input
                  ref={emailRef}
                  type="email" 
                  value={inputValue.email} 
                  onChange={handleInputChange}
                  onKeyDown={(e) => handleKeyDown(e, companyRef)}
                  className="form-control" 
                  id="email" 
                  name="email" 
                  placeholder="Enter Email" 
                  required
                />
                <input
                  ref={companyRef}
                  type="text" 
                  value={inputValue.company} 
                  onChange={handleInputChange}
                  onKeyDown={(e) => handleKeyDown(e, commentsRef)}
                  className="form-control" 
                  id="company" 
                  name="company" 
                  placeholder="Company Name" 
                  required
                />
                <textarea
                  ref={commentsRef}
                  value={inputValue.comments} 
                  onChange={handleInputChange}
                  className="form-control" 
                  rows="5" 
                  id="comments" 
                  name="comments" 
                  placeholder="Comments"
                />
                <ReCAPTCHA dataSize="compact" className="compact"
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                  onChange={onChange}
                />
                <br />
                <button className="btn demo-btn" type="submit" disabled={isSubmitDisabled}>
                  Submit
                </button>
              </form>
              <Popup open={showPopup} onClose={() => setShowPopup(false)}>
                <div>{message && message.message}</div>
              </Popup>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DemoPage;
